import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import { Card,Form,Button,Div } from 'react-bootstrap';
import userImage from '../images/users.png';
import Image from 'react-bootstrap/Image';
import { useContext, useEffect, useState,useMemo } from 'react';
import Replies from './Replies';
import styles from './Comments.module.css';
import { useNavigate } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import axios from 'axios';
import { TiArrowSortedDown,TiArrowSortedUp } from "react-icons/ti";
import {UserContext} from "../App";
function Comments(props) {

    const [offset, setOffset] = useState(0);
    const [scroll,setScroll] = useState(false);
    const[contents,setContent]  = useState([]);
    const[inputs,setInputs] = useState([]);
    const[comment,setComment] = useState('');
    const  navigate = useNavigate();
    const [isReplies,setReplies] = useState([]);
    const [isFocustxtcomment,setFocustxtcomment] = useState(false);
    const [reload,setReload] = useState(false);
    const user = useContext(UserContext);
    const [count,setCount] = useState(0);
    const getCommentmemo = useMemo(()=>{
      return getComment(props.postID,props.Types);
    },[])
        const handlechange =  (event)=>{
      
          const name = event.target.name;
          const value = event.target.value;
          event.target.style.height = `24px`;
          event.target.style.height = `${event.target.scrollHeight}px`;

          setComment(event.target.value);
          setInputs(values=>({...values,[name]:value}));
          
        }
        function clickreplies(event,commentID){
          event.preventDefault();
          const reply = false;
          const myNextList = [...contents];
          
          const replies = myNextList.find(
            a => a.commentID === commentID
          );
          replies.isReply = !replies.isReply;
          setContent(myNextList);
        
        }
        function insertUserID(){
          const name = "userID";
          const value = user.token;
      
          setInputs(values=>({...values,[name]:value}))
        }
        function insertPostID(){
          const name = "postID";
          const value = props.postID;
      
          setInputs(values=>({...values,[name]:value}))
        }
        function insertTypes(){
          const name = "type";
          const value = props.Types;
      
          setInputs(values=>({...values,[name]:value}))
        }
        function getComment(postID,Type){
         
          let axiosConfig = {
            headers: {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
      
            }
          };
          axios.get(`https://whoozhaf.000webhostapp.com/API/comments.php/${postID}/${Type}`,axiosConfig).then(function(response){
            
            const nextisreply = response.data.map((value,i)=>{
              
                const name = "isReply";
                const isreply = false;
                const namevallue = name + isreply;
                return {...value , [name]:isreply};
              
              
          });
    
          setContent(nextisreply);
          
          });
          setCount(count+1);
          }
          function onFocustxtcomment(){
              setFocustxtcomment(true);
          }
          function onBlurtxtcomment(){
            setFocustxtcomment(false);
        }
            useEffect(() => {
                
               // insertUserID();
                insertPostID();
                insertTypes();
             
                getComment(props.postID,props.Types);
             
            }, [props.reload]);
   
            const  addReply = async (event,commentID,inputs)=>{
      
              event.preventDefault();
              let axiosConfig = {
                headers: {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
          
                }
              };
      
              axios.post(`https://whoozhaf.000webhostapp.com/API/replies.php/${commentID}`,inputs,axiosConfig).then(function(response){
             
              
              });
              setReload(!reload);
              navigate('/');
            }
  return (
      

        <Col className={props.prosisActivve?styles.colCommentpros:styles.colCommentcons}  sm={props.Types=="Pros"?props.prosisActivve?"10":"1":props.prosisActivve?"1":"10"} style={{visibility:props.Types=="Pros"?props.prosisActivve?"visible":"hidden":props.prosisActivve?"hidden":"visible" }} >
       {contents.map((content,key)=>
              {
                return(
        <Container key = {key} fluid="md" style={{display:props.Types=="Pros"?props.prosisActivve?"":"none":props.prosisActivve?"none":"" }} className={styles.containerComment}>
        
            <Row  className={styles.rowComment+" "+'justify-content-center'}>
            <Col md="1" lg="2" className = {styles.colLogo}> 
                <Image rounded  src={userImage} className={styles.imgLogo}/>
                
                </Col>
                <Col md="5" lg="10" className = {styles.Colname}> 
                <Stack gap={0} >
                <Card.Text className={styles.cardText}>{content==[] ? null : content.firstname + " " +content.lastname}</Card.Text>
                <Card.Text className={styles.cardTextTime}>{content==[] ? null :content.timeago+" ago"}</Card.Text>
                </Stack>
                
                </Col>
            </Row>
            
            <Row>
            <Col md="12" className={styles.colContent}> 
            <Card.Text className={styles.cardCotent}>
                {content==[] ? null :content.comment}
                
                </Card.Text>
                
                </Col>
            </Row>
            <Row>
            <Col md="12" className={styles.colReplies}>
              <div className={styles.btnReplies}  onClick={event=>clickreplies(event,content.commentID)}>
                          <Card.Text className={styles.repliesText}>

                          {content.isReply==true?<TiArrowSortedUp className={styles.arrowDown} />:<TiArrowSortedDown className={styles.arrowDown} /> }Replies{content.isReply}
                      
                          </Card.Text>  
              </div>            
             </Col>
            </Row>
            <Row style={{display:content.isReply==true?"block":"none"}}>
           
           
          
            {/* Cons Replies */}
            <Replies type={props.Types} commentID={content.commentID} reload = {reload} userID = {props.userID} postID =  {props.postID} addreply = {addReply}/>
           
            {/* Cons Replies */}
            </Row>
              
        </Container>
              );
            }
                
            )
            }
        </Col>
    
  );
}

export default Comments;

