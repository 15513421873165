import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import { Card, Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import userImage from '../images/users.png';
import Image from 'react-bootstrap/Image';
import { HiOutlineMinusCircle, HiOutlinePlusCircle } from "react-icons/hi";

import { BiMessageRoundedDetail } from "react-icons/bi";
import { useEffect, useState, useContext } from 'react';
import PostComments from './Comments'
import styles from './counts.module.css';
import Nav from 'react-bootstrap/Nav';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { UserContext } from "../App";
import { FiPlusCircle } from "react-icons/fi";
import { FiMinusCircle } from "react-icons/fi";
import { FaRegBookmark } from "react-icons/fa";
import { BiMessageAltDetail } from "react-icons/bi";
function Counts({ postID }) {

    const [prosisActivve, setproisActive] = useState(true);
    const [offset, setOffset] = useState(0);
    const [scroll, setScroll] = useState(false);
    const [contents, setContent] = useState([]);
    const [choicecontents, setchoicecontent] = useState([]);
    const [countcomments, setcountcomments] = useState([0]);
    const [countpros, setcountpros] = useState([0]);
    const [countcons, setcountcons] = useState([0]);
    const [inputs, setInputs] = useState([]);
    const [comment, setComment] = useState('');
    const [choice, setchoice] = useState('');
    const navigate = useNavigate();
    const user = useContext(UserContext);
    const [isFocustxtcomment, setFocustxtcomment] = useState(false);



    function handleClick() {
        setproisActive(current => !current);
    }


    useEffect(() => {


        getCountPros(postID);
        getCountCons(postID);
        getCountComments(postID);
        // const onScroll = () => setOffset(window.pageYOffset);
        //if(offset>0){ 
        // setScroll(true); 

        //}
        //else{
        //  setScroll(false); 
        // }

        // clean up code
        //window.removeEventListener('scroll', onScroll);
        // window.addEventListener('scroll', onScroll, { passive: true });
        // return () => window.removeEventListener('scroll', onScroll);
    }, []);



    function getCountPros(postID) {

        let axiosConfig = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',

            }
        };
        axios.get(`http://localhost/API/counts.php/${postID}/pros`, axiosConfig).then(function(response) {

            setcountpros(response.data['count'])

        });

    }

    function getCountCons(postID) {

        let axiosConfig = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',

            }
        };
        axios.get(`http://localhost/API/counts.php/${postID}/cons`, axiosConfig).then(function(response) {

            setcountcons(response.data['count'])

        });

    }

    function getCountComments(postID) {

        let axiosConfig = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',

            }
        };
        axios.get(`http://localhost/API/counts.php/${postID}/comments`, axiosConfig).then(function(response) {

            setcountcomments(response.data['count'])

        });

    }


    return (




        <Row className={styles.rowreact}>
         
        <Col  className={styles.colreact}>
       
               <Stack gap={1} direction="horizontal">
               <BiMessageAltDetail />
               <label className={styles.count}>{countcomments}</label>
               <FiPlusCircle />
               <label className={styles.count}>{countpros}</label>
               <FiMinusCircle />
               <label className={styles.count}>{countcons}</label>
               <FaRegBookmark />
               </Stack>
    
            </Col>
         </Row>

    );
}

export default Counts;