import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import { Card,Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import userImage from '../images/users.png';
import Image from 'react-bootstrap/Image';
import { HiOutlineMinusCircle,HiOutlinePlusCircle } from "react-icons/hi";

import { BiMessageRoundedDetail } from "react-icons/bi";
import { useEffect,useState,useContext, useMemo } from 'react';
import PostComments from './Comments';
import Addcomment from  './addcomment';
import styles from './Content.module.css';
import Nav from 'react-bootstrap/Nav';
import axios from 'axios';
import {useNavigate} from "react-router-dom";
import {UserContext} from "../App";
import Counts from './counts';
function Content({token}) {
  
  const [prosisActivve,setproisActive] = useState(true);
  const [offset, setOffset] = useState(0);
  const [scroll,setScroll] = useState(false);
  const[contents,setContent]  = useState([]);
  const[choicecontents,setchoicecontent]  = useState([]);
  const[countcomments,setcountcomments]  = useState([0]);
  const[countpros,setcountpros] = useState([0]);
  const[countcons,setcountcons] = useState([0]);
  const[inputs,setInputs] = useState([]);
  const[comment,setComment] = useState('');
  const[choice,setchoice] = useState('');
  const  navigate = useNavigate();
  const user = useContext(UserContext);
  const[Reload,setReload] = useState(false);
  const [count,setCount] = useState(0);
  const [isFocustxtcomment,setFocustxtcomment] = useState(false);
  const getConentmemo = useMemo(()=>{
    return getContent();
  },[])
  let axiosConfig = {
    headers: {
        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',

    }
  };

  const handlechange =  (event)=>{

    const name = event.target.name;
    const value = event.target.value;
    setComment(event.target.value);
    setInputs(values=>({...values,[name]:value}));
    
  }
  function insertUserID(){
    const name = "userID";
    const value = token.token;

    setInputs(values=>({...values,[name]:value}))
  }
  function insertType(){
    const name = "choice";
    const value = "";

    setInputs(values=>({...values,[name]:value}))
  }
 
  function handleClick()
    {
      setproisActive(current=>!current);
      }
     
    
      useEffect(() => {
      
          insertUserID();
          insertType();
          getChoiceContent(token.token);
        
         // const onScroll = () => setOffset(window.pageYOffset);
          //if(offset>0){ 
           // setScroll(true); 
         
          //}
          //else{
           //  setScroll(false); 
         // }
          
          // clean up code
          //window.removeEventListener('scroll', onScroll);
         // window.addEventListener('scroll', onScroll, { passive: true });
         // return () => window.removeEventListener('scroll', onScroll);
      }, [Reload,getConentmemo]);
  
      function getChoiceContent(userID){
        let axiosConfig = {
          headers: {
              'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
    
          }
        };
        
        axios.get(`https://whoozhaf.000webhostapp.com/API/choice.php/${userID}`,axiosConfig).then(function(response){
          setchoicecontent(response.data);
     
       
        });
        
      }
      function getContent(){
        let axiosConfig = {
          headers: {
              'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
    
          }
        };
        axios.get("https://whoozhaf.000webhostapp.com/API/post.php",axiosConfig).then(function(response){
          setContent(response.data);
          
          
       
        });
        return contents;
      }
      function getCountPros(postID){
   
        let axiosConfig = {
          headers: {
              'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
    
          }
        };
        axios.get(`https://whoozhaf.000webhostapp.com/API/counts.php/${postID}/pros`,axiosConfig).then(function(response){
        
          setcountpros(response.data['count'])
       
        });
        return countpros;
      }
      function getCountCons(postID){
   
        let axiosConfig = {
          headers: {
              'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
    
          }
        };
        axios.get(`https://whoozhaf.000webhostapp.com/API/counts.php/${postID}/cons`,axiosConfig).then(function(response){
         
          setcountcons(response.data['count'])
       
        });
        return countcons;
      }
      function getCountComments(postID){
     
        let axiosConfig = {
          headers: {
              'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
    
          }
        };
        axios.get(`https://whoozhaf.000webhostapp.com/API/counts.php/${postID}/comments`,axiosConfig).then(function(response){
          console.log(postID);
          setcountcomments(response.data['count'])
       
        });
        return countcomments;
      }
      const cancel = async(event)=>{
        setInputs([]);
        setComment('');
        getContent();
        insertUserID();
  
        setFocustxtcomment(false);
     
      }
      const choicepros = async(event,postID)=>{
        event.preventDefault();
        const choice = "Pros";
       
  
        axios.post(`https://whoozhaf.000webhostapp.com/API/choice.php/${postID}/${choice}`,inputs,axiosConfig).then(function(response){
        console.log(inputs);
     
        });
        getChoiceContent(token.token);
        getContent();
        navigate('/');
        setReload(!Reload);
      }
      const choicecons = async(event,postID)=>{
        event.preventDefault();

        const choice = "Cons";
     
      
        axios.post(`https://whoozhaf.000webhostapp.com/API/choice.php/${postID}/${choice}`,inputs,axiosConfig).then(function(response){
         
       
        });
        getChoiceContent(token.token);
        getContent();
        navigate('/');
        setReload(!Reload);
    }
      const addComment = async(event,postID,choice,inputs)=>{
        event.preventDefault();
      
       
        let axiosConfig = {
          headers: {
              'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
    
          }
        };
      
       
    
        axios.post(`https://whoozhaf.000webhostapp.com/API/comments.php/${postID}/${choice}`,inputs,axiosConfig).then(function(response){
          setComment('');
      
        });
        getContent();
        navigate('/');
        setReload(!Reload);
      }
      function onFocustxtcomment(){
        setFocustxtcomment(true);
      }
        function onBlurtxtcomment(){
          setFocustxtcomment(false);
      }
  return (
    
    <Container fluid="md" className={styles.container} >
    
    
        {contents.map((content,key)=>
              {
                return(
      <Row key={key} className={"justify-content-md-center"}>
        <Col md="6"  className= {styles.cardColumn} > 
        <Card className= {styles.card}  >
        <Card.Header className={styles.rowHeader} ></Card.Header>
      <Card.Body >
        <Card.Title>
        <Container fluid="md">
      
          <Row className={styles.rowName}>
           <Col  xs="2" lg="2" className = {styles.colLogo}> 
              <Image rounded  src={userImage} className={styles.imgLogo} alternate="no image"/>
            </Col>
            <Col xs="6" lg="10" className = {styles.Colname}> 
                  <Stack gap={1}  >
                  <Card.Text className={styles.textName}>{content.firstname + " " +content.lastname}</Card.Text>
                  <Card.Text className={styles.cardTextTime}>{content.timeago} ago</Card.Text>
                  </Stack>
              
            </Col>
          
          </Row>
          <Row className={styles.rowContent}>
       
            <Col md="12" className={styles.colContent}> 
            <Card.Text className={styles.cardCotent}>
             {content.content}
            </Card.Text>
              
            </Col>
          </Row>
      
        </Container>
        </Card.Title>
       
    
        <Container fluid="md" >
          {
            choicecontents.length==0? <Row className={"justify-content-center"+" "+styles.rowChoices}>
            <Col  md={"5"} className={styles.colChoicespros}> 
 
            <Button type="submit" variant="light"  className={styles.textChoicespros} onClick={event=>choicepros(event,content.postID)}>
                        <HiOutlinePlusCircle  className={styles.iconChoicespros}/>{content.pros} 
                   </Button>
         
             </Col>
 
             <Col   md={"5"} className={styles.colChoicescons}> 
             <Button type="submit" variant="light"  className={styles.textChoicescons} onClick={event=>choicecons(event,content.postID)}>
             {content.cons}   
             <HiOutlineMinusCircle className={styles.iconChoicescons}/> 
              </Button>
           
             </Col>
           </Row>
           :choicecontents.filter(choicecontent=>choicecontent.postID==content.postID).length>0?
           choicecontents.filter(choicecontent=>choicecontent.postID==content.postID).map((choicecontent,key)=>
            {
              return(
                
      <Row key={key} className={"justify-content-center"+" "+styles.rowChoices}>
         <Col  md={"5"} className={styles.colChoicespros}> 

         <Button type="submit" variant="light" disabled className={choicecontent.choice=="Pros"?styles.textChoicespros2:styles.textChoicespros} onClick={event=>choicepros(event,content.postID)}>
                     <HiOutlinePlusCircle  className={styles.iconChoicespros}/>{content.pros} 
                </Button>
      
          </Col>

          <Col   md={"5"} className={styles.colChoicescons}> 
          <Button type="submit" variant="light" disabled className={choicecontent.choice=="Pros"?styles.textChoicescons:styles.textChoicescons2} onClick={event=>choicecons(event,content.postID)}>
          {content.cons}   
          <HiOutlineMinusCircle className={styles.iconChoicescons}/> 
           </Button>
        
          </Col>
        </Row>
       
         )
        }
          )
          :<Row key={key}  className={"justify-content-center"+" "+styles.rowChoices}>
          <Col  md={"5"} className={styles.colChoicespros}> 
  
          <Button type="submit" variant="light"  className={styles.textChoicespros} onClick={event=>choicepros(event,content.postID)}>
                      <HiOutlinePlusCircle  className={styles.iconChoicespros}/>{content.pros} 
                 </Button>
       
           </Col>
  
           <Col   md={"5"} className={styles.colChoicescons}> 
           <Button type="submit" variant="light"  className={styles.textChoicescons} onClick={event=>choicecons(event,content.postID)}>
           {content.cons}   
           <HiOutlineMinusCircle className={styles.iconChoicescons}/> 
            </Button>
         
           </Col>
         </Row>

          }
       
      
       <Counts postID  = {content.postID}></Counts>
             
       <Addcomment postID = {content.postID} token={token} Reload={Reload} addcomments={addComment} handlechange={handlechange} comment = {comment} cancel = {cancel} isFocustxtcomment ={isFocustxtcomment}onFocustxtcomment={onFocustxtcomment}></Addcomment>

          <Row className={"justify-content-md-center"}>
           <Col onClick={handleClick}  md={prosisActivve?"10":"1"} className={styles.prosCol}> 
           <Card.Text className={styles.prosText}>
           <HiOutlinePlusCircle  /> {prosisActivve?"Pros":""}  
           </Card.Text>
            </Col>

            <Col  onClick={handleClick}  md={prosisActivve?"1":"10"} className={styles.consCol}> 
            <Card.Text className={styles.consText}>
            {prosisActivve?"":"Cons"}    <HiOutlineMinusCircle />
          </Card.Text>
              
            </Col>
          </Row>
          {/* Comments */}
       
        
          <Row className={"justify-content-md-center"+" "+styles.comment}>
            {/* Pros comments */}
            
            <PostComments prosisActivve={prosisActivve} reload = {Reload} onClick ={handleClick} Types={"Pros"} postID={content.postID} userID = {token.token}/>
             {/* Pros comments */}
            {/* Cons comments */}
            
            <PostComments prosisActivve={prosisActivve}  reload = {Reload} onClick ={handleClick} Types={"Cons"} postID={content.postID} userID = {token.token}/>
             {/* Cons comments */}
          </Row>
         
        </Container>
      </Card.Body>
    </Card></Col>
      </Row>
          );
        }
            
        )
        }
    </Container>
  );
}

export default Content;